export const data = [



{
    "Date":"3-26",
    "Event":"40 Days for Life - 1pm to 7pm",
},
{
    "Date":"4-2",
    "Event":"40 Days for Life - 1pm to 7pm",
},
{
    "Date":"4-9",
    "Event":"40 Days for Life - 1pm to 7pm",
},

{
    "Date":"4-9",
    "Event":"Blood Drive - SSPP 1pm - 5pm",
},
{
    "Date":"4-10",
    "Event":"Council Meeting 7:30pm - (Rosary at 7:00pm)",
},
{
    "Date":"4-12",
    "Event":"Alton Litter Cleanup",
},
{
    "Date":"5-8",
    "Event":"Council Meeting 7:30pm - (Rosary at 7:00pm)",
},
{
    "Date":"6-4",
    "Event":"Blood Drive - Suddes Hall 1pm - 5pm",
},
{
    "Date":"6-12",
    "Event":"Council Meeting 7:30pm - (Rosary at 7:00pm)",
},
{
    "Date":"7-10",
    "Event":"Council Meeting 7:30pm - (Rosary at 7:00pm)",
},
{
    "Date":"8-6",
    "Event":"Blood Drive - Suddes Hall 1pm - 5pm",
},
{
    "Date":"8-14",
    "Event":"Council Meeting 7:30pm - (Rosary at 7:00pm)",
},
{
    "Date":"8-22",
    "Event":"Family Fun Night",
},
{
    "Date":"9-11",
    "Event":"Council Meeting 7:30pm - (Rosary at 7:00pm)",
},

{
    "Date":"10-1",
    "Event":"Blood Drive - SSPP 1pm - 5pm",
},
{
    "Date":"10-9",
    "Event":"Council Meeting 7:30pm - (Rosary at 7:00pm)",
},
{
    "Date":"11-13",
    "Event":"Council Meeting 7:30pm - (Rosary at 7:00pm)",
},
{
    "Date":"12-3",
    "Event":"Blood Drive - SSPP 1pm - 5pm",
},
{
    "Date":"12-11",
    "Event":"Council Meeting 7:30pm - (Rosary at 7:00pm)",
},


];