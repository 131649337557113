export const data = [

    {
    "Position":"Grand Knight",
    "Name":"Larry Kulp"
    
},

{
    "Position":"Chaplain",
    "Name":"Father Chris Uhl"
    
},
{
    "Position":"Deputy Grand Knight",
    "Name":"Bob Saville"
    
},
{
    "Position":"Chancellor",
    "Name":"Garrett Klunk"
},
{ 
    "Position":"Treasurer",
    "Name":"Kerry Miller"

},
{
    "Position":"Advocate",
    "Name":"Paul Dix"
}
,
{
    "Position":"Recorder",
    "Name":"Jim Schwegel"
},
{
    "Position":"Warden",
    "Name":"Andy Springman"
},
{
    "Position":"Inside Guard",
    "Name":"Don Williams"
}
,
{ 
    "Position":"Outside Guard",
    "Name":"Jeremy Russell"
},
{
    "Position":"Financial Secretary",
    "Name":"Joel Buesteton"
}
,
{ 
    "Position":"One Year Trustee",
    "Name":"Chad Klunk"
},
{ 
    "Position":"Two Year Trustee",
    "Name":"Mark Vantrease"
},
{
    "Position":"Three Year Trustee",
    "Name":"Richard Ruedin"
},
{
    "Position":"Lecturer",
    "Name":"Don Ehrman"
},
{ 
    "Position":"District Deputy",
    "Name":"Jeff Lister"
}
];